"use strict";

var _interopRequireDefault = require("/home/deployment/lamp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _localstorage = _interopRequireDefault(require("@/utils/localstorage"));
var _default = exports.default = {
  namespaced: true,
  state: {},
  mutations: {}
};