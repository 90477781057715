"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
var _default = exports.default = {
  name: 'App',
  created: function created() {
    //TODO 每次刷新页面会加载这个方法？是否能在这里判断 必须要缓存的Token ，用户信息等
  }
};