var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "right-menu-item",
                    staticStyle: { color: "red", width: "500px" },
                  },
                  [_vm._v("\n        " + _vm._s(_vm.tips) + "\n      ")]
                ),
                _vm._v(" "),
                _c("search", {
                  staticClass: "right-menu-item",
                  attrs: { id: "header-search", placeholder: "搜索菜单" },
                }),
                _vm._v(" "),
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                  attrs: { id: "screenfull" },
                }),
                _vm._v(" "),
                _c("lang-select", {
                  staticClass: "right-menu-item hover-effect",
                }),
                _vm._v(" "),
                _c(
                  "el-popover",
                  {
                    staticClass: "right-menu-item hover-effect",
                    attrs: {
                      placement: "bottom",
                      width: "400",
                      trigger: "click",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "avue-notice" },
                      [
                        _c("div", { staticClass: "msg-title-content" }, [
                          _c("div", { staticClass: "msg-title" }, [
                            _vm._v("消息"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "msg-title-icon" },
                            [
                              _c("el-switch", {
                                attrs: {
                                  title: "是否自动刷新消息",
                                  "active-color": "#13ce66",
                                  "inactive-color": "#ff4949",
                                },
                                on: { change: _vm.msgRefreshChange },
                                model: {
                                  value: _vm.msgRefresh,
                                  callback: function ($$v) {
                                    _vm.msgRefresh = $$v
                                  },
                                  expression: "msgRefresh",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.tableData.records, function (item) {
                          return _c(
                            "div",
                            { key: item.id, staticClass: "avue-notice__item" },
                            [
                              _c(
                                "div",
                                { staticClass: "avue-notice__content" },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:;" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.view(item)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "avue-notice__title" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "avue-notice__name",
                                            },
                                            [_vm._v(_vm._s(item.title))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "avue-notice__tag" },
                                            [
                                              _c(
                                                "el-tag",
                                                {
                                                  attrs: {
                                                    size: "small",
                                                    effect: "plain",
                                                    type: _vm._f(
                                                      "msgTypeFilter"
                                                    )(
                                                      item.msgType
                                                        ? item.msgType["code"]
                                                        : ""
                                                    ),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.msgType
                                                        ? item.msgType["desc"]
                                                        : ""
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "avue-notice__subtitle",
                                        },
                                        [_vm._v(_vm._s(item.createTime))]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "avue-notice__more" },
                          [
                            _c(
                              "router-link",
                              { attrs: { to: { path: "/resources/msg" } } },
                              [
                                _vm._v("点击查看更多"),
                                _c("i", { staticClass: "el-icon-more" }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "el-badge",
                      {
                        staticClass: "badge-item",
                        attrs: {
                          slot: "reference",
                          value: _vm.tableData.total,
                          max: 99,
                          hidden: _vm.tableData.total <= 0,
                        },
                        slot: "reference",
                      },
                      [_c("i", { staticClass: "el-icon-bell" })]
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c(
                "div",
                { staticClass: "avatar-wrapper" },
                [
                  _c(
                    "el-avatar",
                    { attrs: { fit: "fill", src: _vm.avatar } },
                    [
                      _c("el-avatar", [
                        _vm._v(_vm._s(_vm._f("userAvatarFilter")(_vm.name))),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "user-name" }, [
                    _vm._v(_vm._s(_vm.name)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/profile/index" } },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(_vm._s(_vm.$t("navbar.profile"))),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-dropdown-item", [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.setting },
                      },
                      [_vm._v(_vm._s(_vm.$t("navbar.setting")))]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: "https://github.com/dromara/lamp-cloud",
                      },
                    },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(_vm._s(_vm.$t("navbar.github"))),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: "https://www.kancloud.cn/dromara/lamp-cloud/",
                      },
                    },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(_vm._s(_vm.$t("navbar.docs"))),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.deleteCache },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("navbar.deleteCache")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("navbar.logOut")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }