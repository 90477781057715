exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "", ""]);

// exports
exports.locals = {
	"menuText": "rgba(255,255,255,.7)",
	"menuActiveText": "#fff",
	"subMenuActiveText": "#fff",
	"menuBg": "#393e46",
	"menuHover": "#313131",
	"subMenuBg": "#393e46",
	"subMenuHover": "#313131",
	"sideBarWidth": "200px",
	"menuTextHover": "#fff"
};