"use strict";

var _interopRequireDefault = require("/home/deployment/lamp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.string.starts-with");
var _Breadcrumb = _interopRequireDefault(require("@/components/Breadcrumb"));
var _Hamburger = _interopRequireDefault(require("@/components/Hamburger"));
var _LangSelect = _interopRequireDefault(require("@/components/LangSelect"));
var _localstorage = _interopRequireDefault(require("@/utils/localstorage"));
var _Screenfull = _interopRequireDefault(require("@/components/Screenfull"));
var _HeaderSearch = _interopRequireDefault(require("@/components/HeaderSearch"));
var _Login = _interopRequireDefault(require("@/api/Login.js"));
var _Msg = _interopRequireDefault(require("@/api/Msg.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Breadcrumb: _Breadcrumb.default,
    Hamburger: _Hamburger.default,
    LangSelect: _LangSelect.default,
    Screenfull: _Screenfull.default,
    Search: _HeaderSearch.default
  },
  filters: {
    userAvatarFilter: function userAvatarFilter(name) {
      return name.charAt(0);
    },
    msgTypeFilter: function msgTypeFilter(status) {
      var map = {
        WAIT: "",
        NOTIFY: "success",
        PUBLICITY: "info",
        WARN: "danger"
      };
      return map[status] || "";
    }
  },
  mounted: function mounted() {
    this.tipsScrolling();
    this.loadMyMsg();
    if (this.msgRefresh) {
      this.msgRefreshChange(true);
    }
  },
  data: function data() {
    return {
      tips: ' 演示环境禁止修改、删除重要数据！请登录租户【0000】，账号【lamp_pt】查询其他租户的管理员账号后，登录其他租户测试全部功能!!! ',
      tableData: {
        total: 0,
        records: []
      },
      msgRefresh: _localstorage.default.get('MSG_REFRESH', false),
      // 消息是否自动刷新
      msgRefreshTimer: null
    };
  },
  computed: {
    sidebar: function sidebar() {
      return this.$store.state.setting.sidebar;
    },
    avatar: function avatar() {
      var user = this.$store.state.account.user;
      if (!user["avatar"]) {
        return require("@/assets/avatar/default.jpg");
      } else {
        if (user["avatar"].startsWith("http://") || user["avatar"].startsWith("https://")) {
          return user["avatar"];
        } else {
          return require("@/assets/avatar/".concat(user.avatar));
        }
      }
    },
    name: function name() {
      return this.$store.state.account.user.name;
    },
    userId: function userId() {
      return this.$store.state.account.user.id;
    },
    device: function device() {
      return this.$store.state.setting.device;
    }
  },
  methods: {
    tipsScrolling: function tipsScrolling() {
      var _this = this;
      setInterval(function () {
        //ES6箭头函数
        // 截取首字符串(第一个)
        var head = _this.tips.substring(0, 1);
        // 截取除首字符串外所有字符串(除第一个所有)
        var foot = _this.tips.substring(1);
        // 头尾拼接后赋给data => tit属性
        _this.tips = foot + head;
      }, 1000);
    },
    loadMyMsg: function loadMyMsg() {
      var _this2 = this;
      var params = {
        model: {}
      };
      params.size = 10;
      params.current = 1;
      params.model.isRead = false;
      _Msg.default.page(params, {
        isAlert: false
      }).then(function (response) {
        var res = response.data;
        if (res.isSuccess) {
          _this2.tableData = res.data;
        }
      });
    },
    mark: function mark(ids, callback) {
      _Msg.default.mark(ids).then(function (response) {
        var res = response.data;
        if (typeof callback === "function") {
          callback(ids);
        }
      });
    },
    view: function view(row) {
      var _this3 = this;
      this.mark([row.id], function (ids) {
        _this3.loadMyMsg();
        _this3.$router.push({
          path: "/resources/msg/edit",
          query: {
            id: row.id,
            type: "view"
          }
        });
      });
    },
    msgRefreshChange: function msgRefreshChange(val) {
      var _this4 = this;
      _localstorage.default.save('MSG_REFRESH', val);
      if (val) {
        this.msgRefreshTimer = setInterval(function () {
          _this4.loadMyMsg();
        }, 15000);
      } else {
        clearInterval(this.msgRefreshTimer);
        console.log("定时拉取消息停止了！");
        this.msgRefreshTimer = null;
      }
    },
    toggleSideBar: function toggleSideBar() {
      this.$store.commit("setting/toggleSidebar");
    },
    setting: function setting() {
      this.$store.commit("setting/openSettingBar", true);
    },
    logout: function logout() {
      var _this5 = this;
      var param = {
        token: _localstorage.default.get('TOKEN', ''),
        userId: _localstorage.default.get('USER', {
          id: null
        }).id,
        clientId: process.env.VUE_APP_CLIENT_ID
      };
      _Login.default.logout(param).finally(function () {
        return _this5.clean();
      });
    },
    clean: function clean() {
      _localstorage.default.clear();
      location.reload();
    },
    deleteCache: function deleteCache() {
      var _this6 = this;
      this.$confirm(this.$t("tips.confirmDeleteCache"), this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning"
      }).then(function () {
        _this6.reloadData();
      }).catch(function () {
        // do nothing
      });
    },
    reloadData: function reloadData() {
      var _this7 = this;
      var param = {
        grantType: 'refresh_token',
        refreshToken: _localstorage.default.get('REFRESH_TOKEN', '')
      };
      _Login.default.login(param).then(function (response) {
        var res = response.data;
        if (res.isSuccess) {
          var data = res.data;
          _this7.$store.commit("account/setToken", data['token']);
          _this7.$store.commit("account/setRefreshToken", data['refreshToken']);
          _this7.$store.commit("account/setExpireTime", data['expiration']);
          _this7.$store.commit("account/setUser", {
            id: data.userId,
            account: data.account,
            name: data.name,
            avatar: data.avatar,
            workDescribe: data.workDescribe
          });
          _this7.loadPermissions();
        } else {
          _this7.logout();
        }
      }).catch(function () {
        _localstorage.default.remove("EXPIRE_TIME");
        _localstorage.default.remove("TOKEN");
        _localstorage.default.remove("USER_ROUTER");
        _localstorage.default.remove("PERMISSIONS");
        _this7.logout();
      });
    },
    loadPermissions: function loadPermissions() {
      var _this8 = this;
      _Login.default.getResource().then(function (response) {
        var res = response.data;
        if (res.isSuccess) {
          var authorityResource = res.data;
          var permissionsList = authorityResource.resourceList || [];
          _this8.$store.commit("account/setPermissions", permissionsList);
          _this8.$store.commit("account/setAuthorityResource", authorityResource);
          setTimeout(function () {
            location.reload();
          }, 1000);
        } else {
          _this8.logout();
        }
      }).catch(function () {
        _this8.logout();
      });
    }
  }
};