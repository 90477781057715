"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _myValidate = require("@/utils/my-validate");
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    to: {
      type: String,
      required: true
    }
  },
  computed: {
    isExternal: function isExternal() {
      return (0, _myValidate.isExternal)(this.to);
    },
    type: function type() {
      if (this.isExternal) {
        return 'a';
      }
      return 'router-link';
    }
  },
  methods: {
    linkProps: function linkProps(to) {
      if (this.isExternal) {
        return {
          // is: 'a',
          href: to,
          target: '_blank',
          rel: 'noopener'
        };
      }
      return {
        // is: 'router-link',
        to: to
      };
    }
  }
};