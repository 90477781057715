"use strict";

require("core-js/modules/es7.symbol.async-iterator");
require("core-js/modules/es6.symbol");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.to-string");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hasRole = exports.hasPermission = exports.hasNoRole = exports.hasNoPermission = exports.hasAnyRole = exports.hasAnyPermission = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.regexp.split");
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
// 定义一些和权限有关的 Vue指令

function containsAll(maxList, minList) {
  var newList = minList.filter(function (val) {
    return maxList.indexOf(val) > -1;
  });
  if (newList.length == minList.length) {
    return true;
  } else {
    return false;
  }
}
function isPermitted(allPerms, perm) {
  if (allPerms == null || allPerms.length === 0) {
    return false;
  }
  var _iterator = _createForOfIteratorHelper(allPerms),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var p = _step.value;
      if (p.implies(perm)) {
        return true;
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return false;
}
function WildcardPermission(wildcardString, caseSensitive) {
  this._init_(wildcardString, caseSensitive);
}
WildcardPermission.prototype = {
  constructor: WildcardPermission,
  WILDCARD_TOKEN: "*",
  PART_DIVIDER_TOKEN: ":",
  SUBPART_DIVIDER_TOKEN: ",",
  _init_: function _init_(wildcardString, caseSensitive) {
    if (wildcardString == null || wildcardString.trim().length === 0) {
      throw new Error("权限编码通配符字符串不能为null或空。确保权限字符串的格式正确。");
    }
    wildcardString = wildcardString.trim();
    var parts = wildcardString.split(this.PART_DIVIDER_TOKEN);
    this.parts = [];
    var _iterator2 = _createForOfIteratorHelper(parts),
      _step2;
    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var part = _step2.value;
        var subParts = part.split(this.SUBPART_DIVIDER_TOKEN);
        if (!caseSensitive) {
          var lowerSubParts = [];
          var _iterator3 = _createForOfIteratorHelper(subParts),
            _step3;
          try {
            for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
              var subPart = _step3.value;
              lowerSubParts.push(subPart.toLocaleLowerCase());
            }
          } catch (err) {
            _iterator3.e(err);
          } finally {
            _iterator3.f();
          }
          subParts = lowerSubParts;
        }
        if (subParts.length <= 0) {
          throw new Error("权限编码通配符字符串不能包含只有分隔符的部分，确保权限编码字符串的格式正确。");
        }
        this.parts.push(subParts);
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
    if (this.parts.length <= 0) {
      throw new Error("权限编码通配符字符串不能只包含分隔符，确保权限编码字符串的格式正确。");
    }
  },
  implies: function implies(wp) {
    var otherParts = wp.parts;
    var i = 0;
    var _iterator4 = _createForOfIteratorHelper(otherParts),
      _step4;
    try {
      for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
        var otherPart = _step4.value;
        // 如果此权限的部分数少于其他权限，则此权限中包含的部分数之后的所有内容都将自动隐含，因此返回true
        if (this.parts.length - 1 < i) {
          return true;
        } else {
          var _part = this.parts[i];
          if (!_part.includes(this.WILDCARD_TOKEN) && !containsAll(_part, otherPart)) {
            return false;
          }
          i++;
        }
      }

      // 如果此权限的部分多于其他部分，则仅当所有其他部分都是通配符时才暗示它
    } catch (err) {
      _iterator4.e(err);
    } finally {
      _iterator4.f();
    }
    for (; i < this.parts.length; i++) {
      var part = this.parts[i];
      if (!part.includes(this.WILDCARD_TOKEN)) {
        return false;
      }
    }
    return true;
  }
};

// 必须包含列出的所有权限，元素才显示
var hasPermission = exports.hasPermission = {
  install: function install(Vue) {
    Vue.directive('hasPermission', {
      bind: function bind(el, binding, vnode) {
        // 拥有的所有权限
        var authorityResource = vnode.context.$store.state.account.authorityResource;
        var enabled = authorityResource.enabled;
        if (!enabled) {
          return;
        }
        var flag = true;
        var allPermissions = authorityResource.resourceList;
        var caseSensitive = authorityResource.caseSensitive;
        // 待校验权限 一定要是数组
        var permissions = binding.value;
        if (permissions != null && permissions.length > 0) {
          // 转换拥有的权限
          var allPerms = [];
          var _iterator5 = _createForOfIteratorHelper(allPermissions),
            _step5;
          try {
            for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
              var p = _step5.value;
              allPerms.push(new WildcardPermission(p, caseSensitive));
            }
          } catch (err) {
            _iterator5.e(err);
          } finally {
            _iterator5.f();
          }
          var _iterator6 = _createForOfIteratorHelper(permissions),
            _step6;
          try {
            for (_iterator6.s(); !(_step6 = _iterator6.n()).done;) {
              var strPerm = _step6.value;
              var perm = new WildcardPermission(strPerm, caseSensitive);
              if (!isPermitted(allPerms, perm)) {
                flag = false;
              }
            }
          } catch (err) {
            _iterator6.e(err);
          } finally {
            _iterator6.f();
          }
        }
        if (!flag) {
          if (!el.parentNode) {
            el.style.display = 'none';
          } else {
            el.parentNode.removeChild(el);
          }
        }
      }
    });
  }
};

// 当不包含列出的权限时，渲染该元素
var hasNoPermission = exports.hasNoPermission = {
  install: function install(Vue) {
    Vue.directive('hasNoPermission', {
      bind: function bind(el, binding, vnode) {
        var authorityResource = vnode.context.$store.state.account.authorityResource;
        var enabled = true;
        if (!enabled) {
          return;
        }
        var allPermissions = authorityResource.resourceList;
        var caseSensitive = authorityResource.caseSensitive;
        var permissions = binding.value;
        var flag = true;
        if (permissions != null && permissions.length > 0) {
          // 转换拥有的权限
          var allPerms = [];
          var _iterator7 = _createForOfIteratorHelper(allPermissions),
            _step7;
          try {
            for (_iterator7.s(); !(_step7 = _iterator7.n()).done;) {
              var p = _step7.value;
              allPerms.push(new WildcardPermission(p, caseSensitive));
            }
          } catch (err) {
            _iterator7.e(err);
          } finally {
            _iterator7.f();
          }
          var _iterator8 = _createForOfIteratorHelper(permissions),
            _step8;
          try {
            for (_iterator8.s(); !(_step8 = _iterator8.n()).done;) {
              var strPerm = _step8.value;
              var perm = new WildcardPermission(strPerm, caseSensitive);
              if (isPermitted(allPerms, perm)) {
                flag = false;
              }
            }
          } catch (err) {
            _iterator8.e(err);
          } finally {
            _iterator8.f();
          }
        }
        if (!flag) {
          if (!el.parentNode) {
            el.style.display = 'none';
          } else {
            el.parentNode.removeChild(el);
          }
        }
      }
    });
  }
};

// 只要包含列出的任意一个权限，元素就会显示
var hasAnyPermission = exports.hasAnyPermission = {
  install: function install(Vue) {
    Vue.directive('hasAnyPermission', {
      bind: function bind(el, binding, vnode) {
        var authorityResource = vnode.context.$store.state.account.authorityResource;
        var enabled = authorityResource.enabled;
        if (!enabled) {
          return;
        }
        var flag = false;
        var allPermissions = authorityResource.resourceList;
        var caseSensitive = authorityResource.caseSensitive;
        var permissions = binding.value;
        if (permissions != null && permissions.length > 0) {
          // 转换拥有的权限
          var allPerms = [];
          var _iterator9 = _createForOfIteratorHelper(allPermissions),
            _step9;
          try {
            for (_iterator9.s(); !(_step9 = _iterator9.n()).done;) {
              var p = _step9.value;
              allPerms.push(new WildcardPermission(p, caseSensitive));
            }
          } catch (err) {
            _iterator9.e(err);
          } finally {
            _iterator9.f();
          }
          var _iterator10 = _createForOfIteratorHelper(permissions),
            _step10;
          try {
            for (_iterator10.s(); !(_step10 = _iterator10.n()).done;) {
              var strPerm = _step10.value;
              var perm = new WildcardPermission(strPerm, caseSensitive);
              if (isPermitted(allPerms, perm)) {
                flag = true;
              }
            }
          } catch (err) {
            _iterator10.e(err);
          } finally {
            _iterator10.f();
          }
        }
        if (!flag) {
          if (!el.parentNode) {
            el.style.display = 'none';
          } else {
            el.parentNode.removeChild(el);
          }
        }
      }
    });
  }
};

// 必须包含列出的角色权限，元素才显示
var hasRole = exports.hasRole = {
  install: function install(Vue) {
    Vue.directive('hasRole', {
      bind: function bind(el, binding, vnode) {
        var authorityResource = vnode.context.$store.state.account.authorityResource;
        var enabled = authorityResource.enabled;
        if (!enabled) {
          return;
        }
        var caseSensitive = authorityResource.caseSensitive;
        var allRoles = authorityResource.roleList;
        var roles = binding.value;
        if (!caseSensitive) {
          roles = roles.map(function (item) {
            return item.toLocaleLowerCase();
          });
          allRoles = allRoles.map(function (item) {
            return item.toLocaleLowerCase();
          });
        }
        var flag = true;
        var _iterator11 = _createForOfIteratorHelper(roles),
          _step11;
        try {
          for (_iterator11.s(); !(_step11 = _iterator11.n()).done;) {
            var v = _step11.value;
            if (!allRoles.includes(v)) {
              flag = false;
            }
          }
        } catch (err) {
          _iterator11.e(err);
        } finally {
          _iterator11.f();
        }
        if (!flag) {
          if (!el.parentNode) {
            el.style.display = 'none';
          } else {
            el.parentNode.removeChild(el);
          }
        }
      }
    });
  }
};

// 当不包含列出的角色时，渲染该元素
var hasNoRole = exports.hasNoRole = {
  install: function install(Vue) {
    Vue.directive('hasNoRole', {
      bind: function bind(el, binding, vnode) {
        var authorityResource = vnode.context.$store.state.account.authorityResource;
        var enabled = authorityResource.enabled;
        if (!enabled) {
          return;
        }
        var caseSensitive = authorityResource.caseSensitive;
        var allRoles = authorityResource.roleList;
        var roles = binding.value;
        if (!caseSensitive) {
          roles = roles.map(function (item) {
            return item.toLocaleLowerCase();
          });
          allRoles = allRoles.map(function (item) {
            return item.toLocaleLowerCase();
          });
        }
        var flag = true;
        var _iterator12 = _createForOfIteratorHelper(roles),
          _step12;
        try {
          for (_iterator12.s(); !(_step12 = _iterator12.n()).done;) {
            var v = _step12.value;
            if (allRoles.includes(v)) {
              flag = false;
            }
          }
        } catch (err) {
          _iterator12.e(err);
        } finally {
          _iterator12.f();
        }
        if (!flag) {
          if (!el.parentNode) {
            el.style.display = 'none';
          } else {
            el.parentNode.removeChild(el);
          }
        }
      }
    });
  }
};

// 只要包含列出的任意一个角色，元素就会显示
var hasAnyRole = exports.hasAnyRole = {
  install: function install(Vue) {
    Vue.directive('hasAnyRole', {
      bind: function bind(el, binding, vnode) {
        var authorityResource = vnode.context.$store.state.account.authorityResource;
        var enabled = authorityResource.enabled;
        if (!enabled) {
          return;
        }
        var caseSensitive = authorityResource.caseSensitive;
        var allRoles = authorityResource.roleList;
        var roles = binding.value;
        if (!caseSensitive) {
          roles = roles.map(function (item) {
            return item.toLocaleLowerCase();
          });
          allRoles = allRoles.map(function (item) {
            return item.toLocaleLowerCase();
          });
        }
        var flag = false;
        var _iterator13 = _createForOfIteratorHelper(roles),
          _step13;
        try {
          for (_iterator13.s(); !(_step13 = _iterator13.n()).done;) {
            var v = _step13.value;
            if (allRoles.includes(v)) {
              flag = true;
            }
          }
        } catch (err) {
          _iterator13.e(err);
        } finally {
          _iterator13.f();
        }
        if (!flag) {
          if (!el.parentNode) {
            el.style.display = 'none';
          } else {
            el.parentNode.removeChild(el);
          }
        }
      }
    });
  }
};