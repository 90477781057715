"use strict";

var _interopRequireDefault = require("/home/deployment/lamp-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _AxiosApi = _interopRequireDefault(require("./AxiosApi.js"));
var apiList = {
  captcha: "/oauth/anno/captcha",
  login: "/oauth/noToken/login",
  logout: "/oauth/noToken/logout",
  router: "/oauth/menu/router",
  resource: "/oauth/resource/visible"
};
var _default = exports.default = {
  getCaptcha: function getCaptcha(randomId) {
    return (0, _AxiosApi.default)({
      method: 'GET',
      url: apiList.captcha + "?key=".concat(randomId),
      responseType: 'arraybuffer',
      meta: {
        "X-isToken": false
      }
    });
  },
  login: function login(data, meta) {
    return (0, _AxiosApi.default)({
      method: 'POST',
      url: apiList.login,
      data: data,
      meta: meta
    });
  },
  logout: function logout(data, meta) {
    return (0, _AxiosApi.default)({
      method: 'POST',
      formData: true,
      url: apiList.logout,
      data: data,
      meta: meta
    });
  },
  getRouter: function getRouter(data) {
    return (0, _AxiosApi.default)({
      method: 'GET',
      url: apiList.router,
      data: data || {}
    });
  },
  getResource: function getResource(data) {
    return (0, _AxiosApi.default)({
      method: 'GET',
      url: apiList.resource,
      data: data || {}
    });
  }
};